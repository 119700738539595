<script setup lang="ts">
// icons
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiFlyoutProps {
  align?: 'left' | 'center' | 'right';
  closeable?: boolean;
  flyoutClasses?: string | null;
  name: string;
  open?: boolean;
  showArrow?: boolean;
}
const props = withDefaults(defineProps<CiFlyoutProps>(), {
  align: 'center',
  closeable: true,
  flyoutClasses: null,
  open: true,
  showArrow: false,
});

const emit = defineEmits<{
  close: [value: string];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const slots = useSlots();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const defaultFlyoutStyles = {
  left: 'translateY(100%)',
  center: 'translateX(-50%) translateY(100%)',
  right: 'translateY(100%)',
};

const rootRef = ref<HTMLElement | null>(null);
const flyoutInnerRef = ref<HTMLElement | null>(null);
// const arrowPositionLeft = ref('50%');
// const arrowPositionRight = ref('auto');
// const flyoutWidth = ref('auto');
const flyoutStyles = ref({
  transform: defaultFlyoutStyles[props.align],
});

const arrowStyles = ref({
  transform: 'translateX(-50%) translateY(-50%)',
});

const resizeTimer = ref<ReturnType<typeof setTimeout> | null>(null);

const hasHeadlineSlot = computed(() => {
  return !!slots.headline;
});

const flyoutInnerClasses = computed(() => {
  return [`flyout__inner--${props.align}`, props.flyoutClasses];
});

/********************
 * WATCHER           *
 ********************/
watch(() => props.open, onOpenChange, { immediate: true });

/********************
 * FUNCTIONS         *
 ********************/
function onOpenChange(newValue: boolean) {
  if (newValue) {
    onResize();
    window.addEventListener('mousedown', onClickOutside);
    window.addEventListener('resize', onResize);
    window.addEventListener('touchstart', onClickOutside);
  } else if (!newValue && import.meta.client) {
    window.removeEventListener('mousedown', onClickOutside);
    window.removeEventListener('resize', onResize);
    window.removeEventListener('touchstart', onClickOutside);
  }
}

function onClickOutside(event) {
  if (!props.open) {
    return;
  }

  if (event.target !== rootRef.value && rootRef.value && !rootRef.value.contains(event.target)) {
    closeFlyout();
  }
}

function onResize() {
  if (props.open) {
    clearTimeout(resizeTimer.value);
    resizeTimer.value = setTimeout(() => {
      calcFlyoutWidth();
    }, 200);
  }
}

function calcFlyoutWidth() {
  if (!props.open) {
    return;
  }

  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  if (props.align === 'center') {
    flyoutStyles.value = { ...flyoutStyles.value, maxWidth: `${windowWidth - 30}px !important` };
  }

  flyoutStyles.value = {
    ...flyoutStyles.value,
    transform: `translateX(-50%) translateY(100%) `,
  };

  nextTick(() => {
    const flyoutInner = flyoutInnerRef.value;

    if (!flyoutInner) {
      return;
    }

    const innerRect = flyoutInner.getBoundingClientRect();

    if (props.align !== 'center') {
      flyoutStyles.value = {
        ...flyoutStyles.value,
        transform: `translateY(100%)`,
      };
      // this.calcArrowPosition();
      return;
    }

    if (innerRect.left < 0) {
      // is out of bounce to left
      flyoutStyles.value = {
        ...flyoutStyles.value,
        transform: `translateX(calc(-50% - ${Math.ceil(innerRect.left - 15)}px)) translateY(100%)`,
      };
    } else if (innerRect.left + innerRect.width > windowWidth) {
      // is out of bounce to right
      flyoutStyles.value = {
        ...flyoutStyles.value,
        transform: `translateX(calc(-50% + ${Math.ceil(windowWidth - innerRect.right - 15)}px)) translateY(100%)`,
      };
    } else {
      flyoutStyles.value = {
        ...flyoutStyles.value,
        transform: `translateX(-50%) translateY(100%)`,
      };
    }

    // this.calcArrowPosition();
  });
}

function closeFlyout() {
  emit('close', props.name);
}

/********************
 * HOOKS             *
 ********************/
onBeforeUnmount(() => {
  window.removeEventListener('mousedown', onClickOutside);
  window.removeEventListener('resize', onResize);
  window.removeEventListener('touchstart', onClickOutside);
});
</script>

<template>
  <div
    ref="rootRef"
    class="flyout min-w-0 max-w-full"
  >
    <slot />
    <div
      v-if="props.open"
      ref="flyoutInnerRef"
      class="flyout__inner absolute inset-x-auto bottom-32 z-[100] max-w-none whitespace-normal rounded-[2rem] border border-gray-20 bg-white text-black shadow-[0_4px_70px_rgba(0,0,0,0.5)] lg:whitespace-nowrap"
      :class="flyoutInnerClasses"
      :style="flyoutStyles"
    >
      <!-- header -->
      <div
        class="flex items-center pb-4 pl-4 pt-2"
        :class="{ 'justify-between': hasHeadlineSlot, 'justify-end': !hasHeadlineSlot }"
      >
        <slot name="headline" />
        <CiButton
          v-if="props.closeable"
          tabindex="-1"
          class="button ml-2"
          :icon="faTimesCircle"
          icon-ratio="1"
          icon-class="fill-black m-0"
          @click="closeFlyout"
        >
          <span class="hidden uppercase">{{ $gettext('Close') }}</span>
        </CiButton>
      </div>

      <!-- content slot -->
      <div class="flyout__content relative pt-0">
        <slot name="content" />
      </div>

      <!-- arrow -->
      <div
        v-if="props.showArrow"
        class="flyout__arrow absolute top-0 inline-block border border-gray-20 bg-white p-2"
        :style="arrowStyles"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flyout__inner--left {
  left: 0;
  right: auto;
}

.flyout__inner--center {
  left: 50%;
  transform: translateX(-50%) translateY(100%);
}

.flyout__inner--right {
  left: auto;
  right: 0;
}

.flyout__arrow {
  border-width: 0 1px 1px 0;
}
</style>
