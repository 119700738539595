<script setup lang="ts">
import { useVfm } from 'vue-final-modal';

import type { LocationQuery } from 'vue-router';

// icons
import { faMapMarked, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faMapPin, faTimesCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { searchUiGuestAdult, searchUiGuestChild } from '@/locales/fixtures';

// components
import CiSkeletonDatepicker from '@/components/skeleton/CiSkeletonDatepicker.vue';
import CiLocationInput from '@/components/CiSearch/Location/CiLocationInput.vue';
import CiSearchModal from '@/components/CiSearch/CiSearchModal.vue';

// import { getCurrentURL } from '@/utils/tracking';
import dayjs from '@/utils/day';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiSearchProps {
  collapsible?: boolean;
  isDetailPage?: boolean;
  isMobile?: boolean;
}
const props = withDefaults(defineProps<CiSearchProps>(), {
  collapsible: false,
  isDetailPage: false,
  isMobile: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
// const router = useRouter();
const getRouteBaseName = useRouteBaseName();
const route = useRoute();
const searchStore = useSearchStore();
const vfm = useVfm();
const { $gettext, $ngettext, $pgettext } = useGettext();
const { openModal, closeModal } = useCiModal();

const locationInputRef = ref<InstanceType<typeof CiLocationInput> | null>(null);
const {
  ages,
  childrenCount,
  clearInput,
  clearedMapSection,
  closeOpenType,
  datePickerValues,
  getChildAge,
  hasCampsite,
  hasLocation,
  i18nPromoCampsitesTitle,
  i18nSuggestionTitle,
  isAreaSearch,
  localChildrenCount,
  onAccommodationChange,
  onAccommodationSave,
  onCampingCardChange,
  onChildAgeChange,
  onDateChange,
  onDateClear,
  onDateSave,
  onFlexChange,
  onLocationChange,
  onLocationClear,
  onLocationInputFocus,
  onLocationSelected,
  onPersonCountChange,
  onPressedEnter,
  onResultHighlightChange,
  onSave,
  onSearchSubmit,
  openOpenType,
  pointer,
  pointerBackward,
  pointerForward,
  searchHasChanged,
  showSuggestions,
} = useSearch({});

const {
  i18nAccommodationChoice,
  i18nBenefitCardHeadline,
  i18nFlexDayOne,
  i18nFlexDayThree,
  i18nFlexDayTwo,
  i18nFlexHeadline,
  i18nLabelPitch,
  i18nLabelRentals,
  i18nLocationArea,
  i18nPlaceholderDate,
  i18nPlaceholderLocation,
  i18nResetSelection,
} = useI18nSearchAvailabilities();

/********************
 * REFS & VARS       *
 ********************/
const routeBaseName = computed(() => {
  // return getRouteBaseName(router.currentRoute.value);
  return getRouteBaseName(route);
});

const dates = computed(() => {
  const start = searchStore.searchParams.from ? dayjs(searchStore.searchParams.from).format('D.M.') : null;
  const end = searchStore.searchParams.until ? dayjs(searchStore.searchParams.until).format('D.M.') : null;
  return [start, end];
});

const isMobileVersion = computed(() => {
  return props.isMobile;
});

/********************
 * WATCHER           *
 ********************/
watch(() => route.query, onQueryChange, { immediate: false });

// set focus on location input
watch(
  () => locationInputRef.value,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        newValue.setFocus();
      });
    }
  },
  { immediate: false },
);

watchEffect(() => {
  if (props.isMobile) {
    if (!vfm.get('CiSearchModal') && searchStore.searchOpenType) {
      openSearchModal();
    }
  } else if (vfm.get('CiSearchModal')) {
    closeModal('CiSearchModal');
  }
});

/********************
 * FUNCTIONS         *
 ********************/
function onQueryChange(newValue: LocationQuery) {
  searchStore.searchCollapsed = false;

  if (newValue?.area) {
    clearedMapSection.value = false;
  }
}

function getFormattedDate(type: string, date: string | null) {
  if (!date) {
    return;
  }

  return type === 'start' ? dayjs(date).format('D.M') : dayjs(date).format('D.M.YY');
}

function onMobileSearchClicked() {
  if (!props.isDetailPage) {
    searchStore.searchOpenType = 'location';
  } else {
    searchStore.searchOpenType = 'date';
  }
}

function onFlyoutClose(type: string) {
  if (props.isMobile) {
    return;
  }

  closeOpenType(type);
}

function openSearchModal() {
  openModal(
    {
      component: CiSearchModal,
      attrs: {
        onClose: () => {
          closeModal('CiSearchModal');
        },
      },
    },
    {
      contentTransition: 'translate-y-up',
      contentClass: 'w-full h-full',
    },
  );
}

function toggleSearch(status: boolean) {
  if (!searchStore.searchTouched) {
    onMobileSearchClicked();
  } else {
    searchStore.searchCollapsed = status;
  }
}

function onSubmit(openType: string | null) {
  onSave(openType);
  onSearchSubmit();
}

defineExpose({
  openOpenType,
});
</script>

<template>
  <div
    ref="search"
    class="ci-search relative"
  >
    <!-- display -->
    <div
      v-if="props.collapsible && !searchStore.searchCollapsed && isMobileVersion"
      class="flex min-h-[66px] cursor-pointer items-center rounded-[2rem] border-0 bg-white px-4 text-sm"
      style="box-shadow: 0 0.063rem 0.313rem 0.063rem rgba(0, 0, 0, 0.25)"
      @click="toggleSearch(true)"
    >
      <CiAwesomeIcon
        class="mr-4"
        :icon="faSearch"
        ratio="0.8"
      />
      <div class="flex flex-col truncate">
        <div
          class="search-display__location-date mb-1 flex min-w-0 flex-row"
          :class="{ 'pr-6': searchStore.isCampingCardSearch }"
        >
          <div
            v-if="!isDetailPage"
            class="search-display__location min-w-0 shrink truncate"
          >
            <span
              v-if="!searchStore.searchParams.q && !isAreaSearch"
              class="text-gray-light"
            >
              {{ i18nPlaceholderLocation }}</span>
            <span v-if="!searchStore.searchParams.q && isAreaSearch">{{ i18nLocationArea }}</span>
            <span v-if="searchStore.searchParams.q && searchStore.searchParams.q !== ''">{{
              searchStore.searchParams.q
            }}</span>
          </div>

          <!-- prettier-ignore -->
          <div
            v-if="dates[0] || dates[1]"
            class="search-display__date min-w-0 shrink-0 whitespace-nowrap"
            :class="{ 'search-display__date--no-pipe': isDetailPage }"
          >
            <span v-if="searchStore.searchParams.flex">±</span>
            {{ dates[0] }} - {{ dates[1] }}
            <CiCampingCardLogo
              v-if="searchStore.isCampingCardSearch"
              class="inline"
              width="23"
              height="18"
            />
          </div>
          <div v-else class="search-display__date min-w-0 truncate whitespace-nowrap text-gray-light">
            {{ $gettext('Arrival - Departure') }}
          </div>
        </div>

        <div class="search-display__accommodation-guests flex flex-row">
          <div
            v-if="i18nAccommodationChoice"
            class="min-w-0 truncate"
          >
            {{ i18nAccommodationChoice }}
          </div>
          <div class="search-display__guests whitespace-nowrap">
            <span v-if="childrenCount > 0">
              {{
                $gettext('%{ adults }+%{ children } Guests', {
                  adults: searchStore.searchParams.adults,
                  children: childrenCount,
                })
              }}
            </span>
            <span
              v-else
              tabindex="4"
            >
              {{
                $ngettext('%{ adults } Guest', '%{ adults } Guests', searchStore.searchParams.adults, {
                  adults: searchStore.searchParams.adults,
                })
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile view on home -->
    <div
      v-if="(props.collapsible && searchStore.searchCollapsed) || !props.collapsible || !isMobileVersion"
      class="rounded-[2rem] border-none bg-white"
      :class="{
        'border-none shadow-ci':
          (!isMobileVersion && !props.isDetailPage) || (routeBaseName === 'index' && isMobileVersion),
      }"
      style="box-shadow: 0 0.063rem 0.313rem 0.063rem rgba(0, 0, 0, 0.25)"
    >
      <!-- <client-only> -->
      <div
        v-if="routeBaseName === 'index' && isMobileVersion"
        class="flex h-[66px] cursor-pointer flex-row items-center p-4"
        @click="onMobileSearchClicked"
      >
        <CiAwesomeIcon
          :icon="faSearch"
          class="mr-4"
          ratio="0.8"
        />
        <span class="truncate">{{ i18nPlaceholderLocation }}</span>
      </div>

      <form
        v-else
        class="search__form relative flex min-h-[66px] w-full"
        :class="{
          'flex-row p-2': !isMobileVersion && !props.isDetailPage,
          'flex-col': isMobileVersion || props.isDetailPage,
        }"
        autocomplete="off"
        novalidate
        @keydown.down.prevent="pointerForward"
        @keydown.up.prevent="pointerBackward"
        @keypress.enter.prevent="onPressedEnter"
      >
        <client-only>
          <!-- collapsed button -->
          <div
            v-if="(searchStore.searchCollapsed && isMobileVersion) || props.isDetailPage"
            class="flex flex-row justify-between px-4 pb-2 pt-4"
          >
            <div class="flex flex-row items-center">
              <CiAwesomeIcon
                class="mr-4"
                :icon="faSearch"
                ratio="0.8"
              />
              <span
                v-if="props.isDetailPage"
                class="m-0 font-bold"
              >
                {{ $pgettext('Search input headline on CPDP', 'Availabilities') }}</span>
              <span
                v-else
                class="m-0 font-bold"
              >{{ $gettext('Change search') }}</span>
            </div>

            <div
              class="search__close"
              :class="{ invisible: props.isDetailPage || (props.isDetailPage && isMobileVersion) }"
            >
              <button
                class="button size-[30px]"
                type="button"
                @click="toggleSearch(false)"
              >
                <CiAwesomeIcon
                  :icon="faTimesCircle"
                  ratio="1"
                />
              </button>
            </div>
          </div>

          <!-- location -->
          <div
            v-if="!props.isDetailPage"
            class="search__location relative flex min-w-0 grow items-center"
            :class="{
              'border-y border-gray-30 py-2': isMobileVersion,
              'border-r border-gray-30 pl-2': !isMobileVersion,
            }"
          >
            <CiFlyout
              class="grow"
              :align="isMobileVersion ? 'center' : 'left'"
              :name="'location'"
              :open="!isMobileVersion && searchStore.searchOpenType === 'location'"
              flyout-classes="flyout__location"
              @close="onFlyoutClose"
            >
              <div
                class="flex w-full cursor-pointer flex-col px-4 text-sm leading-[21px]"
                data-cy="ci-search-flyout--location"
                @click="openOpenType('location')"
              >
                <!-- label-wrapper -->
                <div class="mb-1 flex items-center font-medium">
                  <span class="truncate">{{
                    $pgettext('Search input choice label location', 'Camping location')
                  }}</span>
                </div>

                <!-- search value -->
                <div
                  class="truncate focus:outline-none"
                  tabindex="1"
                  @focus="openOpenType('location')"
                >
                  <span
                    v-if="!searchStore.searchParams.q && !isAreaSearch"
                    class="text-gray-light"
                  >
                    {{ i18nPlaceholderLocation }}</span>
                  <span v-if="!searchStore.searchParams.q && isAreaSearch">{{ i18nLocationArea }}</span>
                  <span v-if="searchStore.searchParams.q && searchStore.searchParams.q !== ''">{{
                    searchStore.searchParams.q
                  }}</span>
                </div>
              </div>

              <template #headline>
                <div class="flyout__headline m-0 font-medium">
                  {{ $pgettext('Search flyout headline location', 'Where do you want to camp?') }}
                </div>
              </template>
              <template #content>
                <!-- search value -->
                <div class="px-4 pb-2">
                  <CiLocationInput
                    ref="locationInputRef"
                    :search-string="searchStore.searchParams.q"
                    :is-submit="searchStore.searchTouched"
                    @focus="onLocationInputFocus"
                    @change="onLocationChange"
                    @clear="onLocationClear"
                    @submit="onLocationSelected"
                  />
                </div>

                <div class="px-0">
                  <CiSearchResultList
                    v-if="showSuggestions && hasLocation"
                    :active="pointer"
                    :results="searchStore.suggestions.locations"
                    :title="''"
                    :icon="faMapMarked"
                    title-prop="name"
                    subtitle-prop="subtitle"
                    @search-result-list-click="onLocationSelected"
                    @highlight-change="onResultHighlightChange"
                  />

                  <CiSearchResultList
                    v-if="showSuggestions && hasCampsite"
                    :active="pointer"
                    :index-offset="searchStore.suggestions.locations.length"
                    :results="searchStore.suggestions.campsites"
                    :title="searchStore.searchParams.q?.length > 2 ? i18nSuggestionTitle : i18nPromoCampsitesTitle"
                    :icon="faMapPin"
                    :no-results-message="true"
                    title-prop="name"
                    subtitle-prop="campsite"
                    image-prop="icon_8_5"
                    @search-result-list-click="onLocationSelected"
                    @highlight-change="onResultHighlightChange"
                  />
                </div>
              </template>
            </CiFlyout>

            <!-- clear -->
            <button
              v-if="searchStore.searchParams.q !== '' || (searchStore.searchParams.q === '' && isAreaSearch)"
              class="button search__clear"
              type="button"
              @click.stop="clearInput([{ q: '', area: isAreaSearch }])"
            >
              <CiAwesomeIcon
                :icon="faTimesCircle"
                ratio="0.7"
              />
              Clear Search
            </button>
          </div>

          <!-- date / accommodation & traveller / submit -->
          <div
            class="search__wrapper flex items-center"
            :class="{
              'flex-row': !isMobileVersion && !props.isDetailPage,
              'flex-col': isMobileVersion || props.isDetailPage,
            }"
          >
            <!-- date -->
            <div
              class="group/search-date search__date relative flex h-full items-center"
              :class="{
                'search__date--large border-r border-gray-30': !isMobileVersion && !props.isDetailPage,
                'border-b border-gray-30 py-2': isMobileVersion || props.isDetailPage,
                'border-t border-gray-30': props.isDetailPage,
              }"
            >
              <CiFlyout
                class="w-full"
                :align="props.isDetailPage ? 'left' : 'center'"
                :name="'date'"
                :open="!isMobileVersion && searchStore.searchOpenType === 'date'"
                :flyout-classes="props.isDetailPage ? 'flyout__date flyout__date--detail' : 'flyout__date'"
                @close="onFlyoutClose"
              >
                <div
                  class="flex w-full cursor-pointer flex-col px-4 text-sm leading-[21px]"
                  @click="openOpenType('date')"
                >
                  <!-- label-wrapper -->
                  <div class="mb-1 flex items-center font-medium">
                    <span class="truncate">{{ $pgettext('Search input choice label dates', 'Travel period') }}</span>
                  </div>

                  <!-- search value -->
                  <div
                    class="truncate focus:outline-none"
                    :class="{ 'pr-4': searchStore.isCampingCardSearch }"
                    tabindex="2"
                    @focus="openOpenType('date')"
                  >
                    <span
                      v-if="!searchStore.searchParams.from && !searchStore.searchParams.until"
                      class="text-gray-light"
                    >
                      {{ i18nPlaceholderDate }}</span>
                    <span v-else>
                      <span v-if="searchStore.searchParams.flex">±</span>
                      {{ getFormattedDate('start', searchStore.searchParams.from) }} -
                      {{ getFormattedDate('end', searchStore.searchParams.until) }}
                    </span>
                    <CiCampingCardLogo
                      v-if="searchStore.isCampingCardSearch"
                      class="inline md:hidden"
                    />
                  </div>
                  <div
                    v-if="searchStore.isCampingCardSearch"
                    class="camping-card-logo-desktop absolute bottom-[2px] right-[-3px] hidden group-hover/search-date:hidden md:block"
                  >
                    <CiCampingCardLogo class="mr-2" />
                  </div>
                </div>
                <template #headline>
                  <div class="flyout__headline m-0 font-medium">
                    {{ $pgettext('Search flyout headline date', 'When do you want to camp?') }}
                  </div>
                </template>
                <template #content>
                  <div class="w-full overflow-hidden">
                    <Suspense>
                      <LazyCiDatepicker
                        :value="datePickerValues"
                        @change="onDateChange"
                      />
                      <template #fallback>
                        <CiSkeletonDatepicker />
                      </template>
                    </Suspense>
                  </div>

                  <div class="mt-2 flex flex-col gap-4 divide-y divide-gray-30 px-4">
                    <transition
                      name="translate-y-up"
                      mode="out-in"
                    >
                      <div
                        v-if="datePickerValues[0] && datePickerValues[1]"
                        class="flex flex-col gap-4"
                      >
                        <div>
                          <div class="flyout__headline m-0 font-medium">
                            {{ i18nFlexHeadline }}
                          </div>
                          <div class="flex pt-2">
                            <CiFilterBoolean
                              class="m-0 mr-1"
                              :value="searchStore.searchParams.flex === 1"
                              :name="'1'"
                              :label="i18nFlexDayOne"
                              @input="onFlexChange($event, 1)"
                            />
                            <CiFilterBoolean
                              class="m-0 mx-1"
                              :value="searchStore.searchParams.flex === 2"
                              :name="'2'"
                              :label="i18nFlexDayTwo"
                              @input="onFlexChange($event, 2)"
                            />
                            <CiFilterBoolean
                              class="m-0 ml-1"
                              :value="searchStore.searchParams.flex === 3"
                              :name="'3'"
                              :label="i18nFlexDayThree"
                              @input="onFlexChange($event, 3)"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="flyout__headline m-0 font-medium">
                            {{ i18nBenefitCardHeadline }}
                          </div>
                          <div class="flex pt-2">
                            <CiFilterBoolean
                              class="m-0 mr-1"
                              :value="searchStore.isCampingCardSearch"
                              name="campingcard"
                              label="CampingCard"
                              @input="onCampingCardChange($event)"
                            >
                              <CiCampingCardLogo class="mr-2" />
                            </CiFilterBoolean>
                          </div>
                        </div>
                      </div>
                    </transition>

                    <div v-if="datePickerValues[0] || datePickerValues[1]">
                      <button
                        type="button"
                        class="button button--danger-outline mt-4"
                        @click.stop="onDateClear"
                      >
                        <CiAwesomeIcon
                          :icon="faTrashAlt"
                          ratio="0.8"
                        />
                        <span>{{ i18nResetSelection }}</span>
                      </button>
                    </div>
                  </div>

                  <div class="flex justify-between p-4">
                    <button
                      class="button p-0 text-primary"
                      type="button"
                      @click.stop="openOpenType('accommodation')"
                    >
                      <span>{{ $pgettext('Search flyout action next', 'Next') }}</span>
                    </button>
                    <CiButton
                      v-if="searchStore.searchOpenType !== 'traveller' && !searchStore.searchTouched"
                      class="button button--primary"
                      data-cy="ci-search-flyout--date-submit"
                      @click.stop="onDateSave"
                    >
                      <span>{{ $pgettext('Search flyout action next', 'Next') }}</span>
                    </CiButton>

                    <CiButton
                      v-else
                      class="button button--warning ml-auto"
                      :icon="faSearch"
                      @click.prevent="onSubmit(searchStore.searchOpenType)"
                    >
                      <span>{{ $pgettext('Search button text', 'Search') }}</span>
                    </CiButton>
                  </div>
                </template>
              </CiFlyout>

              <!-- clear -->
              <button
                v-if="searchStore.searchParams.from || searchStore.searchParams.until"
                class="button search__clear"
                type="button"
                @click.stop="clearInput([{ from: null, until: null, flex: null, campingcard: null }])"
              >
                <CiAwesomeIcon
                  :icon="faTimesCircle"
                  ratio="0.7"
                />
                Clear Search
              </button>
            </div>

            <!-- accommodation & traveller -->
            <div
              class="flex h-full"
              :class="{
                'w-full flex-row': isMobileVersion && !props.isDetailPage,
                'w-full flex-col': props.isDetailPage,
              }"
            >
              <!-- accommodation -->
              <div
                class="search__accommodation relative flex items-center"
                :class="{
                  'search__accommodation--large items-center border-r border-gray-30 ':
                    !isMobileVersion && !props.isDetailPage,
                  'items-center border-r border-gray-30 py-2': isMobileVersion && !props.isDetailPage,
                  'items-center border-b border-r border-gray-30':
                    isMobileVersion && searchHasChanged && !props.isDetailPage,
                  '!w-full border-b border-gray-30 py-2': props.isDetailPage,
                }"
              >
                <CiFlyout
                  :align="props.isDetailPage ? 'left' : 'center'"
                  :name="'accommodation'"
                  :open="!isMobileVersion && searchStore.searchOpenType === 'accommodation'"
                  class="w-full"
                  flyout-classes="flyout__accommodation"
                  @close="onFlyoutClose"
                >
                  <div
                    class="flex w-full cursor-pointer flex-col px-4 text-sm leading-[21px]"
                    @click="openOpenType('accommodation')"
                  >
                    <!-- label-wrapper -->
                    <div class="mb-1 flex items-center font-medium">
                      <span class="truncate">
                        {{ $pgettext('Search input choice label accommodation', 'Accommodation type') }}
                      </span>
                    </div>

                    <!-- search value -->
                    <div
                      class="truncate focus:outline-none"
                      :class="{ 'text-gray': !searchStore.searchTouched }"
                      tabindex="3"
                      @focus="openOpenType('accommodation')"
                    >
                      <span>{{ i18nAccommodationChoice }}</span>
                    </div>
                  </div>
                  <template #headline>
                    <div class="flyout__headline m-0 font-medium">
                      {{ $pgettext('Search flyout headline accommodation', 'How would you like to camp?') }}
                    </div>
                  </template>
                  <template #content>
                    <div class="px-4 pb-4">
                      <div class="row no-gutters">
                        <div class="col-12">
                          <CiFilterBoolean
                            class="m-0"
                            :value="
                              searchStore.searchParams.acco_type === 'pitch'
                                || searchStore.searchParams.acco_type === 'all'
                            "
                            :name="i18nLabelPitch"
                            :label="i18nLabelPitch"
                            @input="onAccommodationChange($event, 'pitch')"
                          >
                            <img
                              src="~/assets/svg/icon-pitch.svg"
                              class="mr-4"
                              :alt="`icon ${i18nLabelPitch}`"
                            >
                          </CiFilterBoolean>
                        </div>
                        <div class="col-12">
                          <CiFilterBoolean
                            class="m-0 mt-4"
                            :value="
                              searchStore.searchParams.acco_type === 'acco'
                                || searchStore.searchParams.acco_type === 'all'
                            "
                            :name="i18nLabelRentals"
                            :label="i18nLabelRentals"
                            @input="onAccommodationChange($event, 'acco')"
                          >
                            <img
                              src="~/assets/svg/icon-cabin.svg"
                              class="mr-4"
                              :alt="`icon ${i18nLabelRentals}`"
                            >
                          </CiFilterBoolean>
                        </div>
                      </div>
                      <div class="mt-4 flex justify-between">
                        <button
                          class="button p-0 text-primary"
                          type="button"
                          @click.stop="openOpenType('traveller')"
                        >
                          <span>{{ $pgettext('Search flyout action next', 'Next') }}</span>
                        </button>

                        <CiButton
                          v-if="searchStore.searchOpenType !== 'traveller' && !searchStore.searchTouched"
                          class="button button--primary"
                          data-cy="ci-search-flyout--accommodation-submit"
                          @click.stop="onAccommodationSave"
                        >
                          <span>{{ $pgettext('Search flyout action next', 'Next') }}</span>
                        </CiButton>
                        <CiButton
                          v-else
                          class="button button--warning ml-auto"
                          :icon="faSearch"
                          @click.prevent="onSubmit(searchStore.searchOpenType)"
                        >
                          <span>{{ $pgettext('Search button text', 'Search') }}</span>
                        </CiButton>
                      </div>
                    </div>
                  </template>
                </CiFlyout>

                <!-- clear -->
                <button
                  v-if="searchStore.searchParams.acco_type !== null && searchStore.searchParams.acco_type !== 'all'"
                  class="button search__clear"
                  type="button"
                  @click.stop="clearInput([{ acco_type: null }])"
                >
                  <CiAwesomeIcon
                    :icon="faTimesCircle"
                    ratio="0.7"
                  />
                  Clear Search
                </button>
              </div>

              <!-- traveller -->
              <div
                class="search__guests relative flex items-center"
                :class="{
                  'search__guests--large': !isMobileVersion && !props.isDetailPage,
                  'py-2': isMobileVersion || props.isDetailPage,
                  '!w-full': props.isDetailPage,
                  'border-b border-gray-30':
                    (props.isDetailPage && searchHasChanged)
                    || (props.isDetailPage && !isMobileVersion)
                    || (!props.isDetailPage && searchHasChanged && isMobileVersion),
                }"
              >
                <CiFlyout
                  class="w-full"
                  :align="isMobileVersion ? 'center' : props.isDetailPage ? 'left' : 'right'"
                  :name="'traveller'"
                  :open="!isMobileVersion && searchStore.searchOpenType === 'traveller'"
                  flyout-classes="flyout__guests"
                  @close="onFlyoutClose"
                >
                  <div
                    class="flex w-full cursor-pointer flex-col px-4 text-sm leading-[21px]"
                    @click="openOpenType('traveller')"
                  >
                    <!-- label-wrapper -->
                    <div class="mb-1 flex items-center font-medium">
                      <span class="truncate">
                        {{ $pgettext('Search input choice label guests', 'Camper') }}
                      </span>
                    </div>

                    <!-- search value -->
                    <div
                      class="truncate"
                      :class="{ 'text-gray': !searchStore.searchTouched }"
                      tabindex="4"
                      @focus="openOpenType('traveller')"
                    >
                      <span
                        v-if="childrenCount > 0"
                        key="adultsAndChildren"
                      >
                        {{
                          $gettext('%{ adults }+%{ children } Guests', {
                            adults: searchStore.searchParams.adults,
                            children: childrenCount,
                          })
                        }}
                      </span>
                      <span
                        v-else
                        key="adults"
                        tabindex="4"
                      >
                        {{
                          $ngettext('%{ adults } Guest', '%{ adults } Guests', searchStore.searchParams.adults, {
                            adults: searchStore.searchParams.adults,
                          })
                        }}
                      </span>
                    </div>
                  </div>
                  <template #headline>
                    <div class="flyout__headline m-0 font-medium">
                      {{ $pgettext('Search flyout headline traveller', 'How many people would like to camp?') }}
                    </div>
                  </template>
                  <template #content>
                    <div class="flex flex-col px-4 pb-4">
                      <!-- guests -->
                      <CiGuest
                        class="mb-4"
                        :amount="searchStore.searchParams.adults"
                        :guest="searchUiGuestAdult"
                        :max="20"
                        :min="1"
                        @change="onPersonCountChange"
                      >
                        <img
                          src="~/assets/svg/icon-parents.svg"
                          width="30"
                          height="30"
                          alt="Adults"
                        >
                      </CiGuest>
                      <CiGuest
                        :amount="localChildrenCount"
                        :guest="searchUiGuestChild"
                        :max="20"
                        :min="0"
                        @change="onPersonCountChange"
                      >
                        <img
                          src="~/assets/svg/icon-children.svg"
                          width="25"
                          height="25"
                          alt="Children"
                        >
                      </CiGuest>

                      <!-- age -->
                      <div
                        v-if="localChildrenCount > 0"
                        class="mt-12 w-1/2"
                      >
                        <div class="flyout__headline m-0 font-medium">
                          {{ $gettext('Age of the children on the day of arrival?') }}
                        </div>

                        <CiSelect
                          v-for="(childAge, idx) in localChildrenCount"
                          :key="idx"
                          :options="ages"
                          class="mt-3 block w-full"
                          :value="getChildAge(idx)"
                          @change="onChildAgeChange($event, idx)"
                        />
                      </div>

                      <!-- save -->
                      <CiButton
                        class="button button--warning icon-absolute mt-12 w-full"
                        :icon="faSearch"
                        data-cy="ci-search-flyout--search-submit"
                        @focus="closeOpenType"
                        @click="onSearchSubmit"
                      >
                        <span>{{ $pgettext('Search button text', 'Search') }}</span>
                      </CiButton>
                    </div>
                  </template>
                </CiFlyout>

                <!-- clear -->
                <button
                  v-if="!(searchStore.searchParams.adults === 2 && searchStore.searchParams.children === null)"
                  class="button search__clear"
                  type="button"
                  @click.stop="clearInput([{ adults: 2, children: null }])"
                >
                  <CiAwesomeIcon
                    :icon="faTimesCircle"
                    ratio="0.7"
                  />
                  Clear Search
                </button>
              </div>
            </div>

            <!-- submit -->
            <div
              v-if="!isMobileVersion || searchHasChanged"
              class="flex h-full items-center justify-end"
              :class="{ 'w-full': isMobileVersion || props.isDetailPage }"
            >
              <button
                type="button"
                class="button button--warning"
                :class="{
                  'size-[50px] max-w-none !border-0': !isMobileVersion && !props.isDetailPage,
                  'icon-absolute m-4 w-full': isMobileVersion || props.isDetailPage,
                }"
                name="clearSearch"
                data-cy="ci-search--submit"
                @focus="closeOpenType()"
                @click="onSearchSubmit"
              >
                <span v-if="props.isDetailPage">{{ $gettext('Check availability') }}</span>
                <span
                  v-else
                  :class="{ 'sr-only': !isMobileVersion && !props.isDetailPage }"
                >{{
                  $pgettext('Search button text', 'Search')
                }}</span>
                <CiAwesomeIcon
                  :class="{ '!ml-0': !isMobileVersion && !props.isDetailPage }"
                  :icon="faSearch"
                  :ratio="'0.6'"
                />
              </button>
            </div>
          </div>
        </client-only>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.campsite-detail {
  .camping-card-logo-desktop {
    display: none !important;
  }
}

.ci-search {
  .search__location {
    :deep(.flyout__location) {
      @include media-breakpoint-up(md) {
        min-width: 500px;
        max-width: 500px;
        left: -25px;
      }
    }
  }

  .search__accommodation,
  .search__date,
  .search__location,
  .search__guests {
    .no-touch &:hover {
      .search__clear {
        opacity: 0.4;
        padding: 1rem !important;
        width: 0;
      }
    }
  }

  .search__clear {
    border-radius: 0;
    border: 0;
    font-size: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 !important;
    transition: opacity 0.2s ease-out;
    width: 0;

    .no-touch &:hover {
      opacity: 0.6 !important;
    }
  }

  /* flyout */
  :deep(.flyout__inner) {
    width: 768px;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  :deep(.flyout__date) {
    @include media-breakpoint-up(md) {
      max-width: 500px;
      width: 500px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 800px;
      width: 800px;
    }
  }

  :deep(.flyout__date--detail) {
    @include media-breakpoint-up(md) {
      max-width: 500px;
      width: 500px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 500px;
      width: 500px;
    }
  }

  :deep(.flyout__headline) {
    font-size: 0.875rem;
    white-space: normal;

    @include media-breakpoint-up(sm) {
      white-space: nowrap;
    }
  }
}

.search__date {
  width: 100%;

  &--large {
    width: 155px;
  }
}

.search__accommodation {
  width: 50%;

  &--large {
    width: 190px;
  }
}

.search__guests {
  width: 50%;

  &--large {
    width: 120px;
  }
}

.search-display__date,
.search-display__guests {
  padding: 0;

  &::before {
    font-weight: bold;
    content: '|';
    padding: 0 4px;
  }
}

.search-display__date--no-pipe {
  &::before {
    content: '';
    padding: 0;
  }
}

.search__tooltip {
  &::before {
    border-color: transparent transparent $info transparent;
    border-style: solid;
    border-width: 10px;
    bottom: 100%; /* At the top of the tooltip */
    content: ' ';
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
</style>
