<script setup lang="ts"></script>

<template>
  <img
    src="~/assets/svg/campingcard-logo.svg"
    width="26"
    height="20"
    alt="CampingCard logo"
  >
</template>
