<script setup lang="ts">
// icons
import { faTimesCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiLocationInputProps {
  isSubmit?: boolean;
  searchString: string;
}
const props = withDefaults(defineProps<CiLocationInputProps>(), {
  isSubmit: false,
});

const emit = defineEmits<{
  change: [value: string];
  clear: [value?: any[] | undefined];
  focus: [];
  submit: [];
}>();

defineExpose({
  setFocus,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const locationInputRef = ref<HTMLInputElement | null>(null);

const i18nPlaceholderLocation = computed(() => {
  return $gettext('Country, region, campsite');
});

/********************
 * FUNCTIONS         *
 ********************/
function onClear() {
  setFocus();
  emit('clear', [{ q: '' }]);
}

function setFocus() {
  locationInputRef.value?.focus();
}
</script>

<template>
  <div
    class="flex h-[66px] w-full flex-col rounded-[2rem] border border-gray-40 shadow-[0_2px_8px_rgba(0,0,0,0.1)]"
    @click="setFocus"
  >
    <div class="relative flex h-full items-center p-2">
      <!-- input -->
      <input
        id="location"
        ref="locationInputRef"
        tabindex="1"
        :placeholder="i18nPlaceholderLocation"
        :value="searchString"
        class="search__input input-field truncate !px-4 focus:outline-none"
        type="text"
        data-cy="ci-location-input--input"
        autocomplete="off"
        autofocus
        data-1p-ignore
        @focus="emit('focus')"
        @keydown.esc.prevent="onClear"
        @keydown.enter.prevent="emit('submit')"
        @input="emit('change', ($event.target as HTMLInputElement)?.value)"
      >

      <!-- cta -->
      <div class="flex items-center">
        <!-- clear -->
        <button
          v-if="searchString !== ''"
          class="button mr-1 opacity-40 transition-opacity duration-200 ease-out hover:opacity-60"
          type="button"
          @click.stop="onClear"
        >
          <CiAwesomeIcon
            :icon="faTimesCircle"
            ratio="0.7"
          />
          <span class="hidden">Clear Search</span>
        </button>

        <!-- submit -->
        <button
          type="button"
          class="button button--primary button--circle size-[50px] max-w-none !border-0"
          data-cy="ci-location-input--submit"
          :class="[props.isSubmit ? 'button--warning' : 'button--primary']"
          @click.stop="emit('submit')"
        >
          <span class="sr-only">{{ $pgettext('Search button text', 'Search') }}</span>
          <CiAwesomeIcon
            v-if="!props.isSubmit"
            class="!ml-0"
            :icon="faChevronRight"
            :ratio="'0.6'"
          />
          <CiAwesomeIcon
            v-else
            class="!ml-0"
            :icon="faSearch"
            :ratio="'0.6'"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<style></style>
